import { createStore } from "vuex"
import axios from "axios"

export default createStore({
  state: {
    appConfig: null,
    user: null,
    userPageRights: null,
    userPlugins: null,
  },
  getters: {
    appConfig (state) {
      return state.appConfig
    },
    loggedIn (state) {
      return !!state.user // Returns truthiness of users state (!!)
    },
    userDetails (state) {
      return state.user
    },
    userManager (state) {
      return state.user.is_manager
    },
    userPageRights (state) {
      return state.userPageRights
    },
    userPlugins (state) {
      return state.userPlugins
    },
    testEnvironment (state) {
      return state.appConfig.test_environment
    }
  },
  mutations: {
    SET_USER_DATA (state, userData) {
      state.appConfig = userData.data.app_config
      state.user = userData.data.user
      state.userPageRights = userData.data.pages
      state.userPlugins = userData.data.plugins

      localStorage.setItem("user", JSON.stringify(userData))
      axios.defaults.headers.common["Authorization"] = `Bearer=${
          userData.data.token
      }`
    },
    CLEAR_USER_DATA () {
      localStorage.removeItem("user")
      window.location.assign("/")
    }
  },
  actions: {
    login ({ commit }, userData) {
      commit("SET_USER_DATA", userData)
    },
    logout ({ commit }) {
      // Log user out on API:
      axios.post(window.baseUrl + "users/logout")
          .then(response => {
            if (response.data.success) {
              commit("CLEAR_USER_DATA")
            } else {
              console.log(window.baseUrl + "users/logout endpoint didnt return a successful response")
            }
          })
          .catch(error => { console.log("Error logging out user: " + error) })
    },
  }
})
